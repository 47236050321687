.social {
    table {
        width: calc(1086px + 30px);
        padding: 30px 0 0 30px;
        display: flex;
        flex-direction: column;
    }

    th,
    td {
        padding-top: 16px;
        padding-bottom: 16px;
        width: 180px;
        text-align: center;
    }

    tr {
        display: flex;
        justify-content: space-around;
    }

    thead {
        background-color: #01384d;
        color: #fff;
    }

    tbody {
        tr {
            display: flex;
            border: 1px solid #01384d;
            border-top: none;
        }
        td {
            height: 59px;
            display: flex;
            align-items: center;
            justify-content: center;
            button {
                border: none;
                background: none;
                cursor: pointer;
                margin-left: 10px;
            }
        }
    }
}
