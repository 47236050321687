.nav-bar_wrapper {
  display: flex;
  font-family: "Montserrat";
  color: #000;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
}

nav {
  width: 220px;
  height: calc(100vh - 60px);
  background: #01384d;
}

.logo_wrapper {
  width: 220px;
  background: #01384d;
  padding: 20px 0;
  border: 1px solid #022837;
  a {
    margin-left: 30px;
  }
}

.nav_list {
  display: flex;
  flex-direction: column;
  height: auto;
}

.nav_list_item {
  display: flex;
  align-items: center;
}

.nav_list_item:last-child {
  margin-top: 160px;
}

.nav_link {
  width: 100%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 25px;
  color: #fff;
  padding: 15px;
  padding-left: 30px;
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
  }

  &:hover {
    color: #022837;
    background: white;
  }
}

.active {
  color: #022837;
  background: white;
}

.nav_link--active {
  color: #022837;
  background: white;
}

.title {
  padding: 0;
  margin: 0;
  display: block;
  font-size: 25px;
  font-weight: bold;
  color: white;
}

.logout-modal__button {
  display: block;
  width: 100%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 25px;
  color: #fff;
  padding: 15px;
  padding-left: 30px;
  display: flex;
  align-items: center;
  background-color: #000;
}
