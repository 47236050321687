html {
  height: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  text-decoration: none;
}

#root {
  display: flex;
  width: 100%;
  /* justify-content: space-around; */
}

main{
  width: 100%;
}

.container {
  width: 100%;
  max-width: 1340px;
  margin: 0 auto;
  padding: 20px;
}