.input-file {
    display: none;
}

.form__box {
    display: flex;
    width: 100%;
    max-width: 500px;
    justify-content: space-between;
}

.label-box {
    display: block;
    width: 150px;
    padding: 20px;
    margin-bottom: 20px;
    background: #01384d;
    border-radius: 8px;
    border: none;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
}

.label-box:hover {
    opacity: 0.7;
}

.label-text {
    display: block;
    text-align: center;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.input-heading {
    display: block;
    width: 400px;
    /* margin: 0 auto; */
    margin-bottom: 20px;
    padding: 20px;
    font-size: 16px;
}

.input-info {
    display: block;
    padding: 10px;
    margin-bottom: 20px;
}

.input-time {
    display: block;
    width: 120px;
    padding: 10px;
    margin-bottom: 20px;
}

.btn {
    display: block;
    width: 100px;
    margin-bottom: 20px;
    padding: 10px;
    margin-top: 20px !important; 
    background: #01384d;
    border-radius: 8px;
    border: none;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
}

.btn:hover {
    opacity: 0.7;
}

.btn--margin {
    margin-top: 20px;
}

.btn--position {
    width: 250px !important;
    margin: 0;
    margin-left: 50px !important;
    margin-bottom: 50px !important;
}

.box {
    display: none;
}

.box-block {
    display: block;
}

.select {
    display: block;
    width: 150px;
    padding: 10px;
    margin: 0;
    margin-bottom: 20px;
    background: #01384d;
    border-radius: 8px;
    border: none;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
}

.box-delete {
    display: flex;
    justify-content: space-between;
    width: 400px;
}

.home-about__list {
    display: flex !important;
    width: 100%;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
}

.home-about__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 335px;
    margin: 0;
    margin-bottom: 40px;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(90, 140, 174, 0.25);
    border-radius: 10px;
}

.home-about__item__box {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.home-about__item__info {
    font-family: "Mazzard";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    color: #242424;
}
.home-about__span {
    display: block;
    margin-left: 25px;
    font-family: "Mazzard";
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 40px;
    color: #5a8cae;
}

.status-box{
    display: flex;
    width: 100%;
    max-width: 80px;
    justify-content: space-between;
    text-align: center;
    justify-self: center;

    span{
        font-size: 20px;
    }
}