.login_container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
}

.login_wrapper {
	width: 330px;
	height: 332px;
	padding: 30px;
	border: 1px solid rgba($color: #01384d, $alpha: 0.3);
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	form {
		height: 198px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	input {
		padding: 15px 20px;
		padding-left: 50px;
		border: 1px solid rgba($color: #01384d, $alpha: 0.5);
		color: #01384d;
		border-radius: 7px;
		font-family: "Montserrat";
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 17px;
		outline: none;
		&::placeholder {
			color: rgba($color: #01384d, $alpha: 0.5);
		}
		&:focus {
			border: 1px solid #01384d;
		}
		&:nth-child(1) {
			background: url("../../assets/images/username.svg") no-repeat;
			background-position: 20px;
		}
		&:nth-child(2) {
			background: url("../../assets/images/password.svg") no-repeat;
			background-position: 20px;
		}
	}
	button {
		background: rgba($color: #01384d, $alpha: 0.5);
		border: none;
		color: #fff;
		border-radius: 7px;
		padding: 18px 112px;
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 20px;
		-webkit-transition: 0.5s;
		transition: 0.5s;
		&:hover {
			background: #01384d;
		}
	}
}

.login_title {
	color: #01384d;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	margin-top: 5px;
	margin-bottom: 0;
	text-align: center;
}
