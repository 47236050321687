.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(138, 138, 138, 0.8);
}

.modal__item {
    position: fixed;
    display: block;
    width: 100%;
    max-width: 500px;
    height: 100%;
    max-height: 400px;
    margin: 0;
    margin-bottom: 15px;
    padding: 20px;
    border-radius: 10px;
    background-color: white;
    overflow: auto;
}

.modal--close {
    display: none !important;
}

.add__btn-box {
    position: absolute;
    top: 90%;
    right: 5%;
}

.add__btn {
    width: 150px;
    padding: 20px;
    background: #01384d;
    border-radius: 8px;
    border: none;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
}

.add__btn:hover {
    opacity: 0.5;
}
