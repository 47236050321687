.search {
    display: block;
    width: 100%;
    padding: 15px;
    background: #01384d;

    .search__box{
        display: flex;
        width: 100%;
        max-width: 600px;
        justify-content: space-between;
        margin-left: 10px;

        select{
            display: block;
            width: 100%;
            max-width: 100px;
            padding: 10px;
            border-radius: 10px;
            font-size: 18px;
        }
    }
}

.search-input {
    display: block;
    width: 100%;
    min-width: 400px;
    padding: 15px;
    border: none;
    border-radius: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
}

.search-input--none{
    display: none;
}
